
let _debouncer = null;
export function debounce(fn, timeout = 250) {
    if (_debouncer) {
        clearTimeout(_debouncer);
    }

    _debouncer = setTimeout(fn, timeout);
}

export function clicked_outside_elem(event, elem, candidate) {
    if (!candidate) {
        candidate = event.target;
    }
    if (!candidate.parentNode) {
        return true;
    } else if (candidate == elem) {
        return false;
    } else {
        return clicked_outside_elem(event, elem, candidate.parentNode);
    }
}

export function format_date(d = new Date(), options = {}) {
    const {
        includeTimestamp = true,
        includeDate = true,
        shortMonth = false
    } = options;
    const is_date = Object.prototype.toString.call(d) === '[object Date]';
    if (!is_date && typeof d === 'string') {
        try {
            d = new Date(d);
        } catch (e) {
            console.error('Invalid date!');
            throw e;
        }
    } else if (!is_date) {
        throw new Error('Invalid date!');
    }

    const months = [
        'Januar',
        'Februar',
        'Marts',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'December',
    ];


    let result = `${months[d.getMonth()].slice(0, (shortMonth ? 3 : undefined))} ${d.getFullYear()}`;

    if(includeDate) {
        result = `${d.getDate()}. ${result.toLowerCase()}`;
    }

    if (includeTimestamp) {
        const hours = d
            .getHours()
            .toString()
            .padStart(2, '0');
        const minutes = d
            .getMinutes()
            .toString()
            .padStart(2, '0');
        result += ` ${hours}:${minutes}`;
    }

    return result;
}

export function recreate_node(el) {
    const clone = el.cloneNode(true);
    el.parentNode.replaceChild(clone, el);
    return clone;
}

export function removeEventListeners(el) {
    return recreate_node(el);
}

// getQueryString :: () -> String
export const getQueryString = () => window.location.search.length > 0
    ? decodeURIComponent(window.location.search.substring(1))
    : '';

// getPairs :: () -> [[string, optional<string>]]
export const getPairs = () => getQueryString().split('&').map(x => x.split('='));

// getQueryVariable :: String -> String?
export const getQueryVariable = (key, defaultValue = undefined) => {
    const pair = getPairs().find(p => p[0] === key);
    if (pair && pair.length && pair[0] != null) {
        return pair?.[1] ?? defaultValue
    }

    return undefined;
};

// toQueryString :: Object -> String        (eg {limit: 10} -> '?limit=10')
export const toQueryString = obj => '?' + Object.keys(obj)
    .map(k => k + '=' + obj[k])
    .join('&');



export function initializeFloatingLabels() {
    const floatingInputs = document.getElementsByClassName("floating-label-input");

    for (let i = 0; i < floatingInputs.length; ++i) {
        const container = floatingInputs[i];
        const label = container.getElementsByClassName('floating-label-input--label')[0];
        const input = container.getElementsByClassName('floating-label-input--input')[0];

        input.onfocus = function() {
            if (!label.classList.contains('float')) {
                label.classList.add('float');
            }
        };

        input.onblur = function() {
            if (input.value.length === 0) {
                label.classList.remove('float');
            }
        };

        const ifValueAddFloatClass = () => {
            if (!label.classList.contains('float') && (input.value.length > 0 || input.dataset?.val > 0)) {
                label.classList.add('float');
            }
        };

        input.addEventListener('input', ifValueAddFloatClass);

        ifValueAddFloatClass();

        // do it again after timeout to ensure floats if populate-function is async
        setTimeout(ifValueAddFloatClass, 700);
    }
}

export function escapeHTML(unsafeText) {
    if(typeof(unsafeText) !== 'string') return '';
    let div = document.createElement('div');
    div.innerText = unsafeText;
    return div.innerHTML;
}

export function removeAllQuotes(str) {
    return str
        .replace(/\'/g, '')
        .replace(/\"/g, '')
        .replace(/\`/g, '')
}