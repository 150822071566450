const winstonWrapper = require('./winston_wrapper');
const winston = require('winston');
const expressWinston = require('express-winston');


const format = process.env.NODE_ENV === 'development' ? winston.format.simple() : winston.format.json({ space: null });
const transports = [new winston.transports.Console()]


const logger = new winstonWrapper(winston.createLogger({
    transports,
    format,
}))

const errorLogger = expressWinston.errorLogger({
    transports,
    format,
    level: 'debug',
});

module.exports = {
    errorLogger,
    logger,
};
