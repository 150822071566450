'use strict';

import { genericErrorNotification, n } from 'shared/n';

window.flatmin = {};

import { debounce, format_date, recreate_node, getQueryVariable } from 'shared/helpers';
import validators from 'shared/validators';
import { confirm } from 'shared/confirm';
import { initializeCheckboxes } from 'shared/checkbox';

import { flatten, unflatten } from 'flat';
import { modal } from 'shared/modal';
import { logger } from '../../../shared/utils/logger';

/*
 *       GLOBALS
 * */
const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);

/**
 *  global DOMContentLoaded event
 */

window.modal = modal;
window.addEventListener('DOMContentLoaded', (e) => {
    const renderStats = (stats) => {
        $$('.sidebar-menu > ul > li').forEach((menuItem) => {
            const resourceName = menuItem
                .getAttribute('data-resource')
                .replace(/-([a-z])/g, function (g) {
                    return g[1].toUpperCase();
                })
                .trim();
            const statValue = stats[resourceName];
            const element = menuItem.querySelector('.loadStatValue');
            element.innerHTML = `${element.innerHTML} (${statValue})`;
        });
    };

    if (typeof flatr !== 'undefined') {
        flatr.get_stats_counts().then((res) => {
            renderStats(res.responseJSON);
        });
    }

    Array.from($$('.custom-select-wrapper')).forEach((dropdown) => {

        if (dropdown._wasInitted === true) return;

        dropdown._wasInitted = true;

        dropdown.addEventListener('click', function () {
            this.querySelector('.custom-select').classList.toggle('open');
        });
        initSelectOptions(dropdown);
    });

    window.addEventListener('click', function (e) {
        Array.from($$('.custom-select')).forEach((select) => {
            if (!select.contains(e.target)) {
                select.classList.remove('open');
            }
        });
    });

    // initialize floating-placeholder-inputs
    const floatingInputs = document.getElementsByClassName('floating-label-input');
    for (let i = 0; i < floatingInputs.length; ++i) {
        const container = floatingInputs[i];
        const label = container.getElementsByClassName('floating-label-input--label')[0];
        const input = container.getElementsByClassName('floating-label-input--input')[0];

        input.onfocus = function () {
            if (!label.classList.contains('float')) {
                label.classList.add('float');
            }
        };

        input.onblur = function () {
            if (input.value.length === 0) {
                label.classList.remove('float');
            }
        };

        input.addEventListener('input', function () {
            if (!label.classList.contains('float') && input.value.length > 0) {
                label.classList.add('float');
            }
        });

        if (!label.classList.contains('float') && input.value.length > 0) {
            label.classList.add('float');
        }
    }

    // replace svg checkboxes
    const checkbox_elems = Array.from(document.getElementsByClassName('checkbox_svg'));
    checkbox_elems.forEach((cel) => {
        const svg_html = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path fill="none" fill-rule="evenodd" stroke="${cel.dataset.color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18 5L7 16l-5-5"/>
        </svg>`;
        cel.outerHTML = svg_html;
    });

    // init search inputs
    const searchElement = document.getElementById('search');
    if (searchElement) {
        searchElement.oninput = () =>
            debounce(() =>
                onSearchChange(searchElement.getAttribute('data-page'), searchElement.value),
            );
    }

    // init edit-param-grid
    Array.from($$('.edit-param-grid')).forEach((grid) => {
        if (!grid.dataset.resource) {
            logger.error('.edit-param-grid is missing data-resource attribute:', grid);
            return;
        }

        Array.from(grid.children)
            .filter((child) => !child.classList.contains('static-param-grid-item'))
            .forEach((item) => {
                if (!item.dataset.param || !item.dataset.human) {
                    logger.error('.edit-param-grid > div is missing dataset attributes:', item);
                    return;
                }

                const span = item.querySelector('span');
                if (span) {
                    span.addEventListener('click', () => {
                        if (item.dataset.type) {
                            editValueModal(
                                grid.dataset.resource,
                                item.dataset.param,
                                item.dataset.human,
                                item.dataset.type,
                            );
                        } else {
                            editValueModal(
                                grid.dataset.resource,
                                item.dataset.param,
                                item.dataset.human,
                            );
                        }
                    });
                }
            });
    });

    // show login screen errors
    if (window.location.pathname === '/login') {
        const loginErrorElem = $('#login-error');
        const loginMsgElem = $('#login-msg');

        const errorMessages = {
            bad_credentials: 'Forkert login',
            serious_error: 'Uventet fejl',
            missing_roles: 'Du har ikke adgang til denne side'
        };

        const error = getQueryVariable('login_error');
        const loggedOut = getQueryVariable('logged_out');

        if (error) {
            loginErrorElem.innerText = errorMessages[error] ?? error;
        }

        if (loggedOut) {
            loginMsgElem.innerText = 'Du er nu logget ud';
        }
    }
});

/**
 *  Search
 */

function createSearchResultListItem(resource, data) {
    const listItem = document.createElement('div');
    listItem.className = 'search-result-list-item';
    listItem.addEventListener('click', () => {
        window.location.href = `/${resource}/${data._id}`;
    });

    const generate = {
        users: (data) => {
            let icon;

            if (!data || !data.roles || !data.roles.length) {
                icon = 'user.svg';
            } else if (data.roles.includes('super-admin') || data.roles.includes('admin')) {
                icon = 'star.svg';
            } else if (data.organizations && data.organizations.length) {
                icon = 'home.svg';
            } else {
                icon = 'user.svg';
            }

            return `
                <img alt="" class="list-item-result-icon" src="static/icons/${icon}"></img>
                <div>${data.firstName} ${data.lastName}</div>
                <div class="gray">${data.email}</div>
                <div class="gray">${format_date(data.createdAt)}</div>
                <div class="gray">${data.lastLogin ? format_date(data.lastLogin) : '-'}</div>
                <img class="settings end" src="static/icons/settings.svg"></img>
            `;
        },
        organizations: (data) => `
            <img alt="" class="list-item-result-icon" src="static/icons/binocular.svg"></img>
            <div>${data.name}</div>
            <div class="gray">${data.zip ? data.zip : '-'}</div>
            <div class="gray">${format_date(data.createdAt)}</div>
            <div class="gray">${format_date(data.createdAt)}</div>
            <img class="settings end" src="static/icons/settings.svg"></img>
        `,
        'real-estates': (data) => `
            <img alt="" class="list-item-result-icon" src="static/icons/binocular.svg"></img>
            <div>${data.name}</div>
            <div class="gray">${data.zip ? data.zip : '-'}</div>
            <div class="gray">${data['organization'] && data['organization'].name ? data['organization'].name : '-'
            }</div>
            <div class="gray">${format_date(data.createdAt)}</div>
            <img alt="" class="settings end" src="static/icons/settings.svg"></img>
        `,
        'external-waiting-lists': (data) => `
            <img alt="" class="list-item-result-icon" src="${data.state === 'live'
                ? 'static/icons/check_filled_green.svg'
                : 'static/icons/check_filled_yellow.svg'
            }"></img>
            <div>${data?.realEstate?.name}</div>
            <div class="gray">${data?.realEstate?.zip ?? '-'}</div>
            <div class="gray">${data?.realEstate?.organization?.name ?? '-'}</div>
            <div class="gray">${format_date(data.updatedAt)}</div>
            <img alt="" class="settings end" src="static/icons/settings.svg"></img>
        `,
        'interest-lists': (data) => `
            <img alt="" class="list-item-result-icon" src='static/icons/heart.svg'></img>
            <div>${data?.name}</div>
            <div>${data?.membersCount ?? 0}</div>
            <div class="gray">${format_date(data.createdAt)}</div>
            <div class="gray">${format_date(data.updatedAt)}</div>
        `,
    };

    listItem.innerHTML = generate[resource](data);
    return listItem;
}
function createSearchResultHeaderItem(resource) {
    const generate = {
        users: () => `
            <div>Type</div>
            <div>Navn</div>
            <div>Email</div>
            <div>Oprettet</div>
            <div>Seneste login</div>
            <div></div>
        `,
        organizations: () => `
            <div>Type</div>
            <div>Navn</div>
            <div>Postnr.</div>
            <div>Oprettet</div>
            <div>Seneste Login</div>
            <div></div>
        `,
        'real-estates': () => `
            <div>Type</div>
            <div>Navn</div>
            <div>Postnr.</div>
            <div>Organisation</div>
            <div>Oprettet</div>
            <div></div>
        `,
        'external-waiting-lists': () => `
            <div>Status</div>
            <div>Navn</div>
            <div>Postnr.</div>
            <div>Organisation</div>
            <div>Indsendt</div>
            <div></div>
        `,
        'interest-lists': () => `
            <div></div>
            <div>Navn</div>
            <div>Antal interesserede</div>
            <div>Oprettet</div>
            <div>Sidst opdateret</div>
            <div></div>
        `,
    };
    const header = document.createElement('div');
    header.className = 'search-result-list-item';
    header.setAttribute('role', 'resultheader');
    header.innerHTML = generate[resource]();

    return header;
}

function onSearchChange(resource, term) {
    flatr.search(resource, term).then((res) => {
        const searchResultList = document.getElementById('search-result-list');
        searchResultList.innerHTML = '';
        searchResultList.classList.add(`grid-${resource}`);
        searchResultList.appendChild(createSearchResultHeaderItem(resource));

        res.responseJSON.forEach((data) => {
            searchResultList.appendChild(createSearchResultListItem(resource, data));
        });
    });
}

window.initializeSearch = (resource) => {
    if (resource) {
        onSearchChange(resource, '');
    }
};

/**
 *  Initialize detail view (api + dom)
 */

window.initDetailView = (resource) => {
    const resourceId = window.location.pathname.split('/').reverse()[0];

    const init = (res) => {
        if (res.status !== 200) {
            throw new Error('Flatr-api get organization returned ' + res.statusCode);
        }
        const obj = res.responseJSON;
        window.flatmin[resource] = obj;
        populateInnerTexts(obj);
    };

    if (resource === 'organization') {
        flatr.get_organization(resourceId).then((res) => {
            init(res);
            const organization = res.responseJSON;
            renderOrganizationMembers(organization);
            renderOrganizationRealEstates(organization);
            renderOrganizationActionBarItems(organization);

            flatr.get_plans().then((res) => {
                renderOrganizationPlan(organization, res.responseJSON);
            });
        });
    } else if (resource === 'user') {
        flatr.get_user(resourceId).then((res) => {
            init(res);
            renderUserRoleSelect(res.responseJSON);
            renderUserActionBarItems(res.responseJSON);

            // if user has organizations
            if (Array.isArray(res.responseJSON.organizations)) {
                // map all organization-ids to promises that resolves after a GET /organizations/:orgId
                Promise.all(
                    res.responseJSON.organizations.map((orgId) => {
                        return new Promise((resolve, reject) => {
                            flatr
                                .get_organization(orgId)
                                .then((res) => {
                                    // replace organization-ids in window.flatmin.user.organizations with resolved organization
                                    window.flatmin.user.organizations = window.flatmin.user.organizations.map(
                                        (org) => {
                                            return org === orgId ? res.responseJSON : org;
                                        },
                                    );
                                    resolve();
                                })
                                .catch(reject);
                        });
                    }),
                ).then(() => renderUserOrganizations(window.flatmin.user.organizations));
            }
        });

        flatr.get_user_waiting_list_spots(resourceId).then(res => renderUserWaitingListSpots(res.responseJSON));
    } else if (resource === 'real-estate') {
        flatr.get_realestate(resourceId).then((res) => {
            init(res);
            renderRealEstateOrganizations(res.responseJSON);
            renderRealEstateActionBarItems(res.responseJSON);
        });
    } else if (resource === 'external-waiting-list') {
        flatr.get_external_waitinglist(resourceId).then((res) => {
            const externalWaitingList = res.responseJSON;
            const render = () => {
                init(res);
                renderExternalWaitingListActionBarItems(externalWaitingList);
                renderExternalWaitingListImage(externalWaitingList);
                renderExternalWaitingListOrganization(externalWaitingList);
                renderExternalWaitingListDocuments(externalWaitingList);
                renderExternalWaitingListAccountingBox(externalWaitingList);
                renderExternalWaitingListSpots(externalWaitingList);
                renderExternalWaitingListNewSpot(externalWaitingList);
            };


            // only fetch reepay add ons if waitinglist is in review
            if (externalWaitingList.state === 'review') {
                flatr.get_reepay_add_ons().then((res) => {
                    renderExternalWaitingListAddOns(externalWaitingList, res.responseJSON);
                    render();
                });

            } else {
                render();
            }
        });
    } else if (resource === 'interest-list') {
        flatr.get_interest_list(resourceId).then((res) => {
            init(res);
            renderSlugSelect(res);
            renderInterestListActionBarItems(res.responseJSON);
            renderInterestListMembers(res.responseJSON);
        });
    } else {
        throw new Error(`Resource '${resource}' does not exist`);
    }
};

function populateInnerTexts(obj) {
    const flattenObj = flatten(obj, { delimiter: '.' });
    const elems = $$('.loadInnerText');
    elems.forEach((elem) => {
        const key = elem.dataset.key;
        if (key in flattenObj) {
            elem.innerText = flattenObj[key];
        }
    });
}

/**
 *  api + dom actions: POST, PATCH, PUT, DELETE
 */

window.create_organization = () => {
    const error_elem = $('#create-organization-error');
    const name = document.querySelector('#create-organization-name').value.trim();
    const searchInput = $('#search');

    if (!name || !name.length) {
        error_elem.innerText = 'Navnet kan ikke være tomt';
        return;
    }
    spin();

    flatr
        .create_organization(name, undefined)
        .then((res) => res.responseJSON)
        .then((res) => {
            spin('stop');
            modal('close', 'create-organization');
            searchInput.value = '';
            onSearchChange('organizations', '');
            n('Organisationen blev oprettet');
        })
        .catch((error) => {
            spin('stop');

            if (error && (error.error_msg || error.message)) {
                error_elem.innerText = error.error_msg || error.message;
            } else {
                error_elem.innerText = 'Der opstod en uventet fejl. Prøv igen og hvis problemet fortsætter, kontakt hello@flatr.dk';
                logger.error(`${res.statusCode}`, res.responseJSON || res.responseText);
            }
        });
};
/**
 *  api + dom actions: POST, PATCH, PUT, DELETE
 */

window.create_interest_list = () => {
    const error_elem = $('#create-interest-list-error');
    const name = document.querySelector('#create-interest-list-name').value.trim();
    const searchInput = $('#search');

    if (!name || !name.length) {
        error_elem.innerText = 'Navnet kan ikke være tomt';
        return;
    }
    spin();

    flatr
        .create_interest_list(name)
        .then((res) => res.responseJSON)
        .then((res) => {
            spin('stop');
            modal('close', 'create-interest-list');
            searchInput.value = '';
            onSearchChange('interest-lists', '');
            n('Interesselisten blev oprettet');
        })
        .catch((error) => {
            spin('stop');

            if (error && (error.error_msg || error.message)) {
                error_elem.innerText = error.error_msg || error.message;
            } else {
                error_elem.innerText = 'Der opstod en uventet fejl. Prøv igen og hvis problemet fortsætter, kontakt hello@flatr.dk';
                logger.error(`${res.statusCode}`, res.responseJSON || res.responseText);
            }
        });
};

window.create_real_estate = () => {
    const error_elem = $('#create-real-estate-error');
    const name = document.querySelector('#create-real-estate-name').value.trim();
    const searchInput = $('#search');

    if (!name || !name.length) {
        error_elem.innerText = 'Navnet kan ikke være tomt';
        return;
    }
    spin();

    flatr
        .create_realestate(name, undefined)
        .then((res) => res.responseJSON)
        .then((res) => {
            spin('stop');
            modal('close', 'create-real-estate');
            searchInput.value = '';
            onSearchChange('real-estates', '');
            n('Ejendommen blev oprettet');
        })
        .catch((error) => {
            spin('stop');

            if (error && (error.error_msg || error.message)) {
                error_elem.innerText = error.error_msg || error.message;
            } else {
                error_elem.innerText = 'Der opstod en uventet fejl. Prøv igen og hvis problemet fortsætter, kontakt hello@flatr.dk';
                logger.error(`${res.statusCode}`, res.responseJSON || res.responseText);
            }
        });
};

window.create_user = () => {
    const error_elem = $('#create-user-error');
    const firstName = document.querySelector('#create-user-firstName').value.trim();
    const lastName = document.querySelector('#create-user-lastName').value.trim();
    const email = $('#create-user-email').value.trim();
    const organization = document.querySelector('#create-user-organization').checked;

    const searchInput = $('#search');

    if (!firstName || !firstName.length || !lastName || !lastName.length) {
        error_elem.innerText = 'Navnet kan ikke være tomt';
        return;
    }

    if (!validators.is_email(email)) {
        error_elem.innerText = 'Emailen er ikke gyldig';
        return;
    }

    const userObject = {
        firstName,
        lastName,
        email
    };

    if (organization) {
        userObject.organizationName = 'organisation';
    }

    spin();

    flatr
        .create_user(userObject)
        .then((res) => res.responseJSON)
        .then((res) => {
            spin('stop');
            modal('close', 'create-user');
            searchInput.value = '';
            onSearchChange('users', '');
            n(`Der er sendt en aktiverings mail til ${email}`);
        })
        .catch((res) => {
            spin('stop');

            if (res.status === 409) {
                error_elem.innerText = 'Emailen er allerede brugt';
            } else if (res && (res.error_msg || res.message)) {
                error_elem.innerText = res.error_msg || res.message;
            } else {
                error_elem.innerText = 'Der opstod en uventet fejl. Prøv igen og hvis problemet fortsætter, kontakt hello@flatr.dk';
                logger.error(`${res.statusCode}`, res.responseJSON || res.responseText);
            }
        });
};

window.showMoreActions = (resource) => {
    if (resource === 'user') {
        floatingDropdown();
    }
};

window.sendResetPasswordEmail = () => {
    const userEmail =
        window && window.flatmin && window.flatmin.user ? window.flatmin.user.email : null;
    const message = userEmail
        ? `Er du sikker på at du sende en email til nulstilling af kode til ${userEmail}?`
        : 'Brugeren har ikke nogen email';
    confirm({
        title: `Send email til nulstilling af kodeord?`,
        message: message,
        buttons: [
            {
                text: 'Annuller',
                class: 'btn4',
            },
            {
                text: 'Send',
                class: 'btn1',
                click: function () {
                    flatr
                        .send_reset_password_email(userEmail)
                        .then((res) => {
                            n(`Email blev sendt`);
                        })
                        .catch((res) => {
                            const error = res.responseJSON;

                            if (error && (error.error_msg || error.message)) {
                                n(error.error_msg || error.message, 'error', 3500);
                            } else {
                                genericErrorNotification(res);
                            }
                        });
                },
            },
        ],
    });
};

window.approveExternalWaitingList = () => {
    const realEstateId = window.flatmin['external-waiting-list'].realEstate.realEstateId;
    return flatr
        .approve_external_waitinglist_review(realEstateId)
        .then((res) => {
            if (res.responseJSON.state === 'live') {
                window.location.href = `/external-waiting-lists/${res.responseJSON._id}`;
            }
        })
        .catch((res) => {
            const error = res.responseJSON;

            if (error && (error.error_msg || error.message)) {
                n(error.error_msg || error.message, 'error', 3500);
            } else {
                genericErrorNotification(res);
            }
        });
};

window.rejectExternalWaitingList = () => {
    const realEstateId = window.flatmin['external-waiting-list'].realEstate.realEstateId;
    return flatr
        .reject_external_waitinglist_review(realEstateId)
        .then((res) => {
            window.location.href = `/external-waiting-lists`;
        })
        .catch((res) => {
            const error = res.responseJSON;

            if (error && (error.error_msg || error.message)) {
                n(error.error_msg || error.message, 'error', 3500);
            } else {
                genericErrorNotification(res);
            }
        });
};

function editValueModal(resource, key, human, type = 'string') {
    if (!window.flatmin || !window.flatmin[resource] || !window.flatmin[resource]._id) {
        throw Error('Resource is not initialized correctly');
    }

    let value = flatten(window.flatmin[resource])[key];
    value = value ? value : '';
    const id = window.flatmin[resource]._id;

    let modalId = 'edit-single-param';

    if (type === 'long-string') {
        modalId = 'edit-long-string';
    }

    modal('load', modalId, {
        name: human,
        placeholder: human,
        value: value,
    });

    recreate_node($(`#${modalId}-submit`)); // to remove existing event listeners
    const submit = $(`#${modalId}-submit`);
    const input = $(`#${modalId}-input`);
    const error_elem = $(`#${modalId}-error`);
    submit.addEventListener('click', () => {
        let patch = {};

        if (!input.value || input.value === '') {
            patch[key] = null;
        } else {
            if (type === 'string' || type === 'long-string') {
                patch[key] = input.value + '';
            } else if (type === 'number') {
                patch[key] = parseInt(input.value);
                if (isNaN(patch[key])) {
                    error_elem.innerText = 'Det skal være et tal';
                    return;
                }
            } else if (type === 'decimal') {
                patch[key] = parseFloat(input.value);
                if (isNaN(patch[key])) {
                    error_elem.innerText = 'Det skal være et decimaltal';
                    return;
                }
            }
        }
        patch = unflatten(patch, { delimiter: '.' });
        let patch_function;

        if (resource === 'organization') {
            patch_function = 'patch_organization';
        } else if (resource === 'user') {
            patch_function = 'patch_user';
        } else if (resource === 'real-estate') {
            patch_function = 'patch_realestate';
        } else if (resource === 'external-waiting-list') {
            patch_function = 'patch_external_waiting_list';
        } else if (resource === 'interest-list') {
            patch_function = 'patch_interest_list';
        } else {
            throw new Error(`Resource '${resource}' does not exist`);
        }

        flatr[patch_function](id, patch)
            .then((res) => {
                const updated = res.responseJSON;
                window.flatmin[resource] = updated;
                n(`${human} blev opdateret`);
                modal('close', modalId);
                input.value = '';
                populateInnerTexts(updated);
            })
            .catch((res) => {
                logger.error(res);
                throw new Error(`Flatmin: api returned ${res.status}`);
            });
    });

    modal('open', modalId);
    input.focus();
}

function connectUserToOrganizationModal() {
    searchResourceModal('users', (user) => {
        flatr.add_organization_member(window.flatmin.organization._id, user._id).then((res) => {
            window.flatmin.organization = res.responseJSON;
            n('Brugeren blev forbundet med organisationen');
            renderOrganizationMembers(res.responseJSON);
        });
    });
}

function connectRealEstateToOrganizationModal() {
    searchResourceModal('real-estates', (realEstate) => {
        flatr
            .connect_realestate(window.flatmin.organization._id, realEstate._id)
            .then((res) => {
                window.flatmin.organization = res.responseJSON;
                n('Ejendommen blev forbundet med organisationen');
                renderOrganizationRealEstates(res.responseJSON);
            })
            .catch((res) => {
                const error = res.responseJSON;

                if (error && (error.error_msg || error.message)) {
                    n(error.error_msg || error.message, 'error');
                } else {
                    genericErrorNotification(res);
                }
            });
    });
}

function searchResourceModal(resource, callback) {
    const input = $(`#search-${resource}-input`);
    const container = $(`#search-${resource}-result`);
    container.innerHTML = '';

    input.addEventListener('input', () =>
        debounce(
            searchResource(resource, (chosenResource) => {
                modal('close', `search-${resource}`);
                callback(chosenResource);
            }),
        ),
    );
    modal('open', `search-${resource}`);
    input.value = '';
    input.focus();
}

function searchResource(resource, chooseResourceCallback) {
    const container = $(`#search-${resource}-result`);
    const query = $(`#search-${resource}-input`).value;
    if (query.length < 2) {
        container.innerHTML = '';
        return;
    }
    flatr.search(resource, query, 5).then((res) => {
        const result = res.responseJSON;
        container.innerHTML = '';
        result.forEach((object) => {
            const row = document.createElement('div');
            row.addEventListener('click', () => chooseResourceCallback(object));

            if (resource === 'users') {
                const name = document.createElement('p');
                const email = document.createElement('p');
                name.className = 'small';
                email.className = 'gray small';
                name.innerText = object.name;
                email.innerText = object.email;
                row.appendChild(name);
                row.appendChild(email);
            } else if (resource === 'real-estates') {
                const name = document.createElement('p');
                name.className = 'small';
                name.innerText = object.name;
                row.appendChild(name);
            }

            container.appendChild(row);
        });
    });
}

/**
 *  Spinner
 */

function spin(action = 'start') {
    const spinner = $('.spinner');
    if (action === 'start') {
        spinner.className += ' active';
    } else if (action === 'stop') {
        spinner.className = spinner.className.replace(/\ active/g, '');
    }
}

/*
 *      FLOATING DROPDOWNS
 */

function floatingDropdown(name, x, y, isFixed, width, menu) {
    const closeDropdown = (elem) => {
        elem.style.opacity = '0.0';
        setTimeout(() => {
            try {
                elem.parentNode.removeChild(elem);
            } catch (e) { }
        }, 350); // a little more than opacity transition time
    };

    const existing = $(`.floating-dropdown[data-id='${name}']`);
    if (existing) {
        closeDropdown(existing);
        return;
    }

    const dropdown = document.createElement('ul');
    dropdown.className = 'floating-dropdown';
    dropdown.dataset.id = name;

    if (isFixed) {
        dropdown.style.position = 'fixed';
    }

    dropdown.style.width = width;

    if (x < 0) {
        dropdown.style.right = -x + 'px';
    } else {
        dropdown.style.left = x + 'px';
    }
    if (y < 0) {
        dropdown.style.bottom = -y + 'px';
    } else {
        dropdown.style.top = y + 'px';
    }
    menu.forEach((item) => {
        const li = document.createElement('li');
        if (item.class === 'seperator') {
            const seperator = document.createElement('span');
            seperator.className = 'seperator';
            li.appendChild(seperator);
            dropdown.appendChild(li);
            return;
        }

        const a = document.createElement('a');

        if (item.class) {
            li.className = item.class;
        }

        a.innerText = item.text();
        a.addEventListener('click', item.click);
        li.appendChild(a);
        dropdown.appendChild(li);
    });
    document.body.appendChild(dropdown);

    window.dropdown_click_event = () => {
        document.body.removeEventListener('mouseup', window.dropdown_click_event, false);

        closeDropdown(dropdown);
    };

    document.body.addEventListener('mouseup', window.dropdown_click_event, false);
}

/*
 *      CUSTOM SELECTS
 */

function initSelectOptions(elem) {
    for (const option of elem.querySelectorAll('.custom-option')) {
        const clicked = (dispatchChangeEvent) =>
            function () {
                if (!this.classList.contains('selected')) {
                    const alreadySelected = this.parentNode.querySelector(
                        '.custom-option.selected',
                    );

                    if (alreadySelected) {
                        alreadySelected.classList.remove('selected');
                    }

                    this.classList.add('selected');
                    this.closest('.custom-select').querySelector(
                        '.custom-select__trigger span',
                    ).textContent = this.textContent;

                    if (dispatchChangeEvent) {
                        const select = this.closest('.custom-select');
                        const event = new CustomEvent('selectchanged', {
                            bubbles: true,
                            detail: this.dataset.value,
                        });
                        select.dispatchEvent(event);
                    }
                }

                const wrapper = this.parentNode.parentNode.parentNode;
                wrapper.classList.remove('invalid');
                option.addEventListener('click', function () { });
            };

        option.addEventListener('click', clicked(true));
        option.addEventListener('init', clicked(false));
    }
}

function chooseSelectOption(select, val, dispatchChangeEvent = true) {
    for (const option of select.querySelectorAll('.custom-option')) {
        if (!option.dataset || !option.dataset.value) {
            continue;
        }

        if (option.dataset.value === val) {
            option.dispatchEvent(new CustomEvent(dispatchChangeEvent ? 'click' : 'init'));
            select.children[0].classList.remove('open');
            return;
        }
    }
}

function renderSlugSelect(res) {
    const slugSelect = $('#interest-list-blog-slug-select');

    const loadInnerText = slugSelect.querySelector('.loadBlogSlugInnerText');
    let text = 'Vælg blog post';
    interestListsBlogPostItems.forEach((x) => {
        if (x.slug === res.responseJSON.blogSlug) {
            text = x.name;
        }
    });
    loadInnerText.innerHTML = text;

    slugSelect.addEventListener('selectchanged', function (evt) {
        const blogSlug = evt.detail;
        flatr
            .patch_interest_list(res.responseJSON._id, { blogSlug })
            .then((res) => {
                window.flatmin['interest-list'] = res.responseJSON;
                n('Success');
            })
            .catch((res) => {
                const error = res.responseJSON;

                if (error && (error.error_msg || error.message)) {
                    n(error.error_msg || error.message, 'error', 3500);
                } else {
                    genericErrorNotification(res);
                }
            });
    });
}

/*
 *      FRONTEND RENDERING
 */

function renderOrganizationMembers(organization) {
    const container = $('.org-related-users > .grid');
    container.innerHTML = '';
    organization.members.forEach((member) => {
        const memberContainer = document.createElement('div');

        const selectContainer = document.createElement('div');
        selectContainer.className = 'custom-select-wrapper';
        const select = `
            <div class="custom-select">
                <div class="custom-select__trigger small">
                    <span></span>
                    <div class="arrow"></div>
                </div>
                <div class="custom-options">
                    <span class="custom-option" data-value="organization-editor">Editor</span>
                    <span class="custom-option" data-value="organization-admin">Admin</span>
                    <span class="custom-option" data-value="organization-owner">Owner</span>
                </div>
            </div>
        `;
        selectContainer.innerHTML = select;

        const name = document.createElement('div');
        name.innerText = member.user.name;
        name.addEventListener('click', function (evt) {
            window.location.href = `/users/${member.user._id}`;
        });

        const removeContainer = document.createElement('div');
        removeContainer.classList.add('remove-icon-btn');
        removeContainer.classList.add('flex-right');
        const remove = document.createElement('img');
        remove.src = '/static/icons/remove.svg';
        remove.addEventListener('click', () => {
            confirm({
                title: `Fjern bruger`,
                message: `Er du sikker på at du vil fjerne ${member.user.name} fra ${organization.name}?`,
                buttons: [
                    {
                        text: 'Annuller',
                        class: 'btn4',
                    },
                    {
                        text: 'Fjern bruger',
                        class: 'btn1',
                        click: function () {
                            flatr
                                .remove_organization_member(organization._id, member.user._id)
                                .then((res) => {
                                    window.flatmin.organization = res.responseJSON;
                                    n(`${member.user.name} blev slettet fra organisationen`);
                                    memberContainer.parentNode.removeChild(memberContainer);
                                })
                                .catch((res) => {
                                    const error = res.responseJSON;

                                    if (error && (error.error_msg || error.message)) {
                                        n(error.error_msg || error.message, 'error');
                                    } else {
                                        genericErrorNotification(res);
                                    }
                                });
                        },
                    },
                ],
            });
        });
        removeContainer.appendChild(remove);
        memberContainer.appendChild(name);
        memberContainer.appendChild(selectContainer);
        memberContainer.appendChild(removeContainer);
        container.appendChild(memberContainer);

        setTimeout(() => {
            const selectWrapper = memberContainer.querySelector('.custom-select-wrapper');
            selectWrapper.addEventListener('click', function () {
                this.querySelector('.custom-select').classList.toggle('open');
            });
            initSelectOptions(selectWrapper.querySelector('.custom-select'));

            const roles = ['organization-admin', 'organization-owner', 'organization-editor'];
            const selectedRole = member.roles.find((role) => roles.includes(role));

            chooseSelectOption(selectWrapper, selectedRole, false);

            selectWrapper.addEventListener('selectchanged', (evt) => {
                const role = evt.detail;

                flatr
                    .patch_organization_member(organization._id, member.user._id, {
                        roles: [role],
                    })
                    .then((res) => {
                        n('Brugerrollen blev opdateret');
                        renderOrganizationMembers(res.responseJSON);
                        window.flatmin.organization = res.responseJSON;
                    })
                    .catch((res) => {
                        const error = res.responseJSON;

                        if (error && (error.error_msg || error.message)) {
                            n(error.error_msg || error.message, 'error');
                        } else {
                            genericErrorNotification(res);
                        }
                    });
            });
        }, 50);
    });

    // add connect-new-user button
    const btn = document.createElement('button');
    btn.addEventListener('click', connectUserToOrganizationModal);
    btn.innerText = '+ Forbind bruger';
    btn.className = 'btn6';
    container.appendChild(btn);
}

function renderOrganizationActionBarItems(organization) {
    const actionBar = document.getElementById('organization-action-bar');
    const moreElem = document.createElement('div');
    moreElem.classList.add('more-btn');

    const moreIcon = document.createElement('img');
    moreIcon.src = '/static/icons/more.svg';
    moreElem.appendChild(moreIcon);
    moreElem.addEventListener('click', (e) => {
        const x = e.target.offsetLeft + 100;
        const y = e.target.offsetTop;
        floatingDropdown(`organization-action-${organization._id}`, x, y, false, '215px', [
            {
                text: () => 'Slet andelsboligforening',
                class: 'text-coral',
                click: () => {
                    confirm({
                        title: `Slet andelsboligforeningen?`,
                        message: `Er du sikker på at du vil slette andelsboligforeningen '${organization.name}? Dette kan ikke fortrydes`,
                        buttons: [
                            {
                                text: 'Annuller',
                                class: 'btn4',
                            },
                            {
                                text: 'Fjern',
                                class: 'btn1',
                                click: () => {
                                    flatr
                                        .delete_organization(organization._id)
                                        .then(() => {
                                            n(
                                                `Andelsboligforeningen ${organization.name} blev slettet`,
                                                'succes',
                                                5000,
                                            );
                                            setTimeout(() => {
                                                window.location = '/organizations';
                                            }, 1500);
                                        })
                                        .catch((res) => {
                                            const error = res.responseJSON;

                                            if (error && (error.error_msg || error.message)) {
                                                n(error.error_msg || error.message, 'error');
                                            } else {
                                                genericErrorNotification(res);
                                            }
                                        });
                                },
                            },
                        ],
                    });
                },
            },
        ]);
    });

    actionBar.appendChild(moreElem);
}

function renderRealEstateActionBarItems(realEstate) {
    const actionBar = document.getElementById('real-estate-action-bar');
    const moreElem = document.createElement('div');
    moreElem.classList.add('more-btn');
    const moreIcon = document.createElement('img');
    moreIcon.src = '/static/icons/more.svg';
    moreElem.appendChild(moreIcon);
    moreElem.addEventListener('click', (e) => {
        const x = e.target.offsetLeft + 175;
        const y = e.target.offsetTop;
        floatingDropdown(`real-estate-action-${realEstate._id}`, x, y, false, '140px', [
            {
                text: () => 'Slet ejendom',
                class: 'text-coral',
                click: () => {
                    confirm({
                        title: `Slet ejendommen?`,
                        message: `Er du sikker på at du vil slette ejendommen '${realEstate.name}? Dette kan ikke fortrydes`,
                        buttons: [
                            {
                                text: 'Annuller',
                                class: 'btn4',
                            },
                            {
                                text: 'Fjern',
                                class: 'btn1',
                                click: () => {
                                    flatr
                                        .delete_realestate(realEstate._id)
                                        .then(() => {
                                            n(
                                                `Ejendommen ${realEstate.name} blev slettet`,
                                                'succes',
                                                5000,
                                            );
                                            setTimeout(() => {
                                                window.location = '/real-estates';
                                            }, 1500);
                                        })
                                        .catch((res) => {
                                            logger.error(res);
                                            const error = res.responseJSON;

                                            if (error && (error.error_msg || error.message)) {
                                                n(error.error_msg || error.message, 'error');
                                            } else {
                                                genericErrorNotification(res);
                                            }
                                        });
                                },
                            },
                        ],
                    });
                },
            },
        ]);
    });

    actionBar.appendChild(moreElem);
}

function renderInterestListActionBarItems(interestList) {
    const actionBar = document.getElementById('interest-list-action-bar');
    const moreElem = document.createElement('div');
    moreElem.classList.add('more-btn');
    const moreIcon = document.createElement('img');
    moreIcon.src = '/static/icons/more.svg';
    moreElem.appendChild(moreIcon);
    moreElem.addEventListener('click', (e) => {
        const x = e.target.offsetLeft + 140;
        const y = e.target.offsetTop + 10;
        floatingDropdown(`interest-list-action-${interestList._id}`, x, y, false, '180px', [
            {
                text: () => 'Slet interesseliste',
                class: 'text-coral',
                click: () => {
                    confirm({
                        title: `Slet interesseliste?`,
                        message: `Er du sikker på at du vil slette interesselisten '${interestList.name}? Der er ${interestList.membersCount} på listen og de vil blive fjernet. Dette kan ikke fortrydes`,
                        buttons: [
                            {
                                text: `Slet liste og ${interestList.membersCount} ${interestList.membersCount === 1 ? 'abonnent' : 'abonnenter'
                                    }`,
                                class: 'btn1',
                                click: () => {
                                    flatr
                                        .delete_interest_list(interestList._id)
                                        .then(() => {
                                            n(
                                                `Andelsboligforeningen ${interestList.name} blev slettet`,
                                                'succes',
                                                5000,
                                            );
                                            setTimeout(() => {
                                                window.location = '/interest-lists';
                                            }, 1500);
                                        })
                                        .catch((res) => {
                                            const error = res.responseJSON;

                                            if (error && (error.error_msg || error.message)) {
                                                n(error.error_msg || error.message, 'error');
                                            } else {
                                                genericErrorNotification(res);
                                            }
                                        });
                                },
                            },
                            {
                                text: 'Annuller',
                                class: 'btn4',
                            },
                        ],
                    });
                },
            },
        ]);
    });

    actionBar.appendChild(moreElem);
}

function renderOrganizationRealEstates(organization) {
    const container = $('.org-related-real-estates > .grid');
    container.innerHTML = '';
    organization.realEstates.forEach((realEstate) => {
        const realEstateContainer = document.createElement('div');
        const name = document.createElement('div');
        name.innerText = realEstate.name;
        name.addEventListener('click', () => {
            window.location.href = `/real-estates/${realEstate._id}`;
        });
        const removeContainer = document.createElement('div');
        removeContainer.classList.add('remove-icon-btn');
        removeContainer.classList.add('flex-right');
        const remove = document.createElement('img');
        remove.src = '/static/icons/remove.svg';
        remove.addEventListener('click', () => {
            confirm({
                title: `Fjern ejendom fra organisation`,
                message: `Er du sikker på at du vil fjerne ejendommen ${realEstate.name} fra ${organization.name}?`,
                buttons: [
                    {
                        text: 'Annuller',
                        class: 'btn4',
                    },
                    {
                        text: 'Fjern',
                        class: 'btn1',
                        click: function () {
                            flatr
                                .disconnect_realestate(organization._id, realEstate._id)
                                .then((res) => {
                                    window.flatmin.organization = res.responseJSON;
                                    n('Ejendommen blev frakoblet');
                                    renderOrganizationRealEstates(res.responseJSON);
                                });
                        },
                    },
                ],
            });
        });
        removeContainer.appendChild(remove);
        realEstateContainer.appendChild(name);
        realEstateContainer.appendChild(removeContainer);
        container.appendChild(realEstateContainer);
    });

    // add connect-new-user button
    const btn = document.createElement('button');
    btn.addEventListener('click', connectRealEstateToOrganizationModal);
    btn.innerText = '+ Forbind ejendom';
    btn.className = 'btn6';
    container.appendChild(btn);
}

function renderOrganizationPlan(organization, plans) {
    const container = $('.org-plan > .grid');

    container.innerHTML = '';
    plans.forEach((plan) => {
        const planContainer = document.createElement('div');

        const isSelected =
            (organization.plan && organization.plan._id === plan._id) ||
            organization.plan?.parentPlan === plan._id;

        if (isSelected) {
            planContainer.className = 'selected';
        }
        const planName = document.createElement('p');
        planName.innerText = plan.name[0].toUpperCase() + plan.name.slice(1);
        planContainer.appendChild(planName);
        container.appendChild(planContainer);

        planContainer.addEventListener('click', () => {
            // load modal with data
            let planToPreload = isSelected ? organization.plan : plan;
            modal('load', 'edit-plan', {
                name: planToPreload.name[0].toUpperCase() + planToPreload.name.slice(1),
                priceYear: planToPreload.payment.priceYear,
                priceMonth: planToPreload.payment.priceMonth,
                maxSubscribers: planToPreload.limitations.maxSubscribers,
            });
            Array.from(document.getElementsByName('plan-payment-interval')).find(
                (e) => e.value === planToPreload.payment.interval,
            ).checked = true;

            $('#plan-externalList').checked = planToPreload.limitations.externalList;
            $('#plan-internalList').checked = planToPreload.limitations.internalList;
            $('#plan-multipleRealEstate').checked = planToPreload.limitations.multipleRealEstate;

            // clear modal submit event
            const oldSubmit = $('#edit-plan-submit');
            const newSubmit = oldSubmit.cloneNode(true);
            oldSubmit.parentNode.replaceChild(newSubmit, oldSubmit);

            // set new modal submit event
            newSubmit.addEventListener('click', () => {
                const error_elem = $('#edit-plan-error');

                // payment
                const priceYear = parseInt($('#plan-yearly-price').value);
                const priceMonth = parseInt($('#plan-monthly-price').value);
                let interval;

                try {
                    interval = Array.from(document.getElementsByName('plan-payment-interval')).find(
                        (e) => e.checked,
                    ).value;
                } catch (e) {
                    error_elem.innerText = 'Betalingsintervallet er ikke defineret';
                    return;
                }

                // limitations
                const maxSubscribers = parseInt($('#plan-maxSubscribers').value);
                const externalList = $('#plan-externalList').checked;
                const internalList = $('#plan-internalList').checked;
                const multipleRealEstate = $('#plan-multipleRealEstate').checked;

                // validate
                const validateNumbers = [
                    ['Årlig pris', priceYear],
                    ['Månedlig pris', priceMonth],
                    ['Maks opskrevne', maxSubscribers],
                ];
                for (let i = 0; i < validateNumbers.length; i++) {
                    const val = validateNumbers[i];
                    if (isNaN(val[1])) {
                        error_elem.innerText = `'${val[0]}' skal være et tal`;
                        return;
                    }
                }

                const payload = {
                    name: plan.name,
                    payment: {
                        priceYear,
                        priceMonth,
                        interval,
                    },
                    limitations: {
                        maxSubscribers,
                        externalList,
                        internalList,
                        multipleRealEstate,
                    },
                };

                flatr
                    .update_plan(organization._id, payload)
                    .then((res) => {
                        window.flatmin.organization = res.responseJSON;
                        renderOrganizationPlan(res.responseJSON, plans);
                        modal('close', 'edit-plan');
                        n('Planen blev opdateret');
                    })
                    .catch((res) => {
                        const body = res.responseJSON;
                        if (body.message) {
                            error_elem.innerText = body.message;
                            return;
                        }
                    });
            });

            modal('open', 'edit-plan');
        });
    });
}

function renderUserActionBarItems(user) {
    const actionBar = document.getElementById('user-action-bar');

    const moreElem = document.createElement('div');
    moreElem.classList.add('more-btn');

    const moreIcon = document.createElement('img');
    moreIcon.src = '/static/icons/more.svg';
    moreElem.appendChild(moreIcon);

    moreElem.addEventListener('click', (e) => {
        const x = e.target.offsetLeft + 195;
        const y = e.target.offsetTop;
        floatingDropdown(`users-action-${user._id}`, x, y, false, '120px', [
            {
                text: () => 'Slet bruger',
                class: 'text-coral',
                click: () => {
                    const isOwner = user.organizations.some((organization) =>
                        organization.members.some(
                            (member) =>
                                member.roles.includes('organization-owner') &&
                                (member.user._id === user._id || member.user === user._id),
                        ),
                    );
                    if (isOwner) {
                        n(
                            'Det er ikke tilladt at slette en bruger som er ejer af en organisation. Skift ejer på organisationerne før du sletter.',
                            'alert',
                            7500,
                        );
                    } else {
                        confirm({
                            title: `Slet bruger?`,
                            message: `Er du sikker på at du vil slette '${user.name}? Dette kan ikke fortrydes`,
                            buttons: [
                                {
                                    text: 'Annuller',
                                    class: 'btn4',
                                },
                                {
                                    text: 'Fjern',
                                    class: 'btn1',
                                    click: () => {
                                        flatr
                                            .delete_user(user._id)
                                            .then(() => {
                                                n('Brugeren blev slettet');
                                                setTimeout(() => {
                                                    window.location = '/users';
                                                }, 1500);
                                            })
                                            .catch((res) => {
                                                const error = res.responseJSON;

                                                if (error && (error.error_msg || error.message)) {
                                                    n(error.error_msg || error.message, 'error');
                                                } else {
                                                    genericErrorNotification(res);
                                                }
                                            });
                                    },
                                },
                            ],
                        });
                    }
                },
            },
        ]);
    });

    actionBar.appendChild(moreElem);
}

function renderUserRoleSelect(user) {
    const editRoleSelect = $('#edit-user-role-select');
    editRoleSelect.addEventListener('selectchanged', function (evt) {
        const role = evt.detail;
        flatr
            .patch_user(window.flatmin.user._id, {
                roles: [role],
            })
            .then((res) => res.responseJSON)
            .then((res) => (window.flatmin.user = res))
            .catch((res) => {
                if (res.responseJSON) {
                    if (res.responseJSON.message) {
                        n(res.responseJSON.message, 'error');
                    }
                }
            });
    });

    const role = ['super-admin', 'admin', 'user'].find((r) => user.roles.includes(r));
    chooseSelectOption(editRoleSelect, role, false);
}

function renderUserOrganizations(organizations) {
    const container = $('.detailview-box.user-organizations > .grid');
    organizations.forEach((organization) => {
        const div = document.createElement('div');
        const name = document.createElement('p');
        name.innerText = organization.name;
        div.appendChild(name);
        div.addEventListener('click', () => {
            window.location.href = `/organizations/${organization._id}`;
        });
        container.appendChild(div);
    });
}

function renderUserWaitingListSpots(spots) {
    const element = (type, attributes = {}) => {
        const e = document.createElement(type);
        for (const [key, value] of Object.entries(attributes)) {
            if (typeof e[key] !== 'undefined') {
                e[key] = value;
            }
            else {
                e.setAttribute(key, value);
            }
        }

        return e;
    };

    const container = $('.detailview-box.user-spots');
    spots.forEach(spot => {
        const grid = element('div', {
            class: 'grid'
        });

        grid.appendChild(element('div', { innerHTML: `<a href="/external-waiting-lists/${spot.externalWaitingList._id}">${spot.externalWaitingList.realEstate.name}</a>` }));
        grid.appendChild(element('div', { innerText: spot.subject.name }));
        grid.appendChild(element('div', { innerText: spot.position }));

        const deleteButton = element('img', { src: '/static/icons/remove.svg' });
        deleteButton.addEventListener('click', () => {
            confirm({
                title: `Fjern ventelisteopskrivning`,
                message: `Er du sikker på, at du vil fjerne ${spot.subject.name}, fra ${spot.externalWaitingList.realEstate.name}?`,
                buttons: [
                    {
                        text: 'Annuller',
                        class: 'btn4',
                    },
                    {
                        text: 'Fjern ventelisteopskrivning',
                        class: 'btn1',
                        click: function () {
                            flatr
                                .delete_external_waitinglist_spot(spot.externalWaitingList._id, spot._id)
                                .then((res) => {
                                    n(`${spot.subject.name} er blevet fjernet fra ventelisten`);
                                    grid.parentNode.removeChild(grid);
                                })
                                .catch((res) => {
                                    const error = res.responseJSON;

                                    if (error && (error.error_msg || error.message)) {
                                        n(error.error_msg || error.message, 'error');
                                    } else {
                                        genericErrorNotification(res);
                                    }
                                });
                        },
                    },
                ],
            });
        });

        grid.appendChild(deleteButton);

        container.appendChild(grid);
    });
}

function renderExternalWaitingListActionBarItems(externalWaitingList) {
    const actionBar = document.getElementById('external-waiting-list-action-bar');

    const moreElem = document.createElement('div');
    moreElem.classList.add('more-btn');

    const moreIcon = document.createElement('img');
    moreIcon.src = '/static/icons/more.svg';
    moreElem.appendChild(moreIcon);

    if (externalWaitingList.isDraft && externalWaitingList.state === 'review') {
        const approveButton = document.createElement('button');
        approveButton.innerHTML = 'Godkend venteliste';
        approveButton.className = 'btn3';
        approveButton.addEventListener('click', function () {
            spin('start');
            approveExternalWaitingList().then(() => spin('stop'));
        });
        const rejectButton = document.createElement('button');
        rejectButton.innerHTML = 'Afvis venteliste';
        rejectButton.className = 'btn1 outlined';
        rejectButton.addEventListener('click', function () {
            spin('start');
            rejectExternalWaitingList().then(() => spin('stop'));
        });

        actionBar.appendChild(approveButton);
        actionBar.appendChild(rejectButton);
    } else {
        actionBar.appendChild(document.createElement('div'));
    }

    actionBar.appendChild(moreElem);
}

function renderExternalWaitingListImage(externalWaitingList) {
    const input = $('#new-external-list-image');
    const imageInput = input.parentNode;

    const imageUrl = (awsData) => {
        return `${window.organizationAppurl}/s/${awsData.awsMetadata?.Key}`;
    };

    if (externalWaitingList.image) {
        imageInput.style.backgroundImage = `url('${imageUrl(externalWaitingList.image)}-lg.jpg')`;
        imageInput.className = 'new-external-list-pic-upload uploaded';
    }

    input?.addEventListener('change', (evt) => {
        const file = evt.target.files[0];
        if (!file) return;
        if (!['image/png', 'image/jpeg', 'image/bmp'].includes(file.type)) {
            n('Billedformatet skal være enten png eller jpg', 'error', 5000, 0);
            return;
        }
        const data = new FormData();
        data.append('upload', file, file.name);

        const realEstateId = window.flatmin['external-waiting-list'].realEstate.realEstateId;
        flatr
            .upload_external_waitinglist_picture(
                realEstateId,
                data,
                window.flatmin['external-waiting-list'].isDraft,
            )
            .then((res) => {
                n('Billedet blev uploadet');
                evt.target.parentNode.style.backgroundImage = `url('${imageUrl(
                    res.responseJSON?.image,
                )}-lg.jpg')`;
                evt.target.parentNode.className = 'new-external-list-pic-upload uploaded';
            })
            .catch((res) => {
                const error = res.responseJSON;
                if (error && error.message) {
                    n(error.message, 'error', 5000, 0);
                } else {
                    genericErrorNotification(res);
                }
            });
    });
}

function renderExternalWaitingListDocuments(externalWaitingList) {
    const documentsContainer = $('#external-waiting-list-documents > .grid');

    externalWaitingList.documents.forEach(doc => {
        const icon = document.createElement('img');
        icon.src = '/static/icons/download.svg';

        const text = document.createElement('div');
        text.innerText = doc.awsMetadata.originalname;

        const docContainer = document.createElement('a');
        docContainer.setAttribute('href', `/s/${doc.awsMetadata.Key}`);
        docContainer.setAttribute('target', '_blank');

        docContainer.appendChild(text);
        docContainer.appendChild(icon);
        documentsContainer.appendChild(docContainer);
    });
}

function renderExternalWaitingListOrganization(externalWaitingList) {
    const organizationId = externalWaitingList?.organization?._id;
    const container = $('#external-waiting-list-organization > .grid');
    container.innerHTML = '';
    if (organizationId) {
        flatr
            .get_organization(organizationId)
            .then((res) => {
                const orgElem = document.createElement('div');
                const nameElem = document.createElement('p');
                nameElem.innerText = res.responseJSON.name;
                orgElem.appendChild(nameElem);
                orgElem.addEventListener('click', () => {
                    window.location.href = `/organizations/${organizationId}`;
                });
                container.appendChild(orgElem);
            })
            .catch(genericErrorNotification);
    } else {
        const p = document.createElement('p');
        p.className = 'gray';
        p.innerText = 'Ejendommen er ikke tilknyttet en organisation';
        container.appendChild(p);
    }
}

function renderExternalWaitingListAddOns(externalWaitingList, addOns) {
    addOns = addOns ?? [];

    const box = document.querySelector('#external-waiting-list-addons');
    const container = box.querySelector('.grid4');
    box.className = box.className.replace(/\ hidden/g, '');

    const renderAddonCheckbox = (addon) => {
        const { handle } = addon;
        const label = document.createElement('label');
        label.className = 'checkbox';
        const input = document.createElement('input');
        input.type = 'checkbox';
        if (externalWaitingList.addOns.includes(addon.handle)) {
            input.checked = true;
        }
        const square = document.createElement('span');
        square.className = 'check-square';
        const check = document.createElement('i');
        check.className = 'checkbox_svg';
        check.dataset.color = '#313131';
        const desc = document.createElement('p');
        desc.innerText = addon.name + ` (${addon.amount / 100},-)`;
        label.appendChild(input);
        label.appendChild(square);
        label.appendChild(check);
        label.appendChild(desc);
        container.appendChild(label);
        input.addEventListener('change', (e) => {
            const checked = e.target.checked;
            if (externalWaitingList.addOns.includes(handle) && checked) {
                n(
                    `${externalWaitingList.realEstate.name} har allerede fået tilføjet ${addon.name}`,
                );
                return;
            }
            const patch = {
                addOns: checked
                    ? [...externalWaitingList.addOns, handle]
                    : externalWaitingList.addOns.filter((a) => a !== handle),
            };

            flatr.patch_external_waiting_list(externalWaitingList._id, patch).then((res) => {
                window.flatmin.externalWaitingList = res.responseJSON;
                const msg = `${addon.name} blev ${checked ? 'tilføjet til' : 'fjernet fra'} ${externalWaitingList.realEstate.name
                    }'s venteliste`;
                n(msg);
            });
        });
    };

    addOns.forEach(renderAddonCheckbox);
    initializeCheckboxes();
}

function renderExternalWaitingListAccountingBox(externalWaitingList) {
    const box = document.querySelector('#external-waiting-list-accounting');
    box.className = box.className.replace(/\ hidden/g, '');
}

function renderExternalWaitingListSpots(externalWaitingList) {
    const element = (type, attributes = {}) => {
        const e = document.createElement(type);
        for (const [key, value] of Object.entries(attributes)) {
            if (typeof e[key] !== 'undefined') {
                e[key] = value;
            }
            else {
                e.setAttribute(key, value);
            }
        }

        return e;
    };

    const stateString = (state, isActivePlan) => {
        return {
            invitation_sent: 'Invitation sendt',
            invitation_opened: 'Invitation åbnet',
            invitation_scheduled: 'Sat til afsendelse',
            pending: 'Bruger oprettet',
            accepted: isActivePlan ? 'Aktiv' : 'Passiv',
            denied: 'Afvist',
            ready_for_invite: 'Klar til afsendelse',
            has_errors: 'Ret fejl',
        }[state] ?? `Ukendt (${state})`;
    }

    const oldSubmit = $('#edit-spot-position-submit');
    const newSubmit = oldSubmit.cloneNode(true);
    oldSubmit.parentNode.replaceChild(newSubmit, oldSubmit);

    newSubmit.addEventListener('click', () => {
        const errorContainer = document.querySelector('#edit-spot-position-error');
        errorContainer.innerText = '';

        if (!window.spotPositionId) {
            errorContainer.innerText = 'Mangler window.spotPositionId?';
            return;
        }

        const position = parseInt(document.querySelector('#edit-spot-position-input').value);
        if (isNaN(position)) {
            errorContainer.innerText = 'Positionen skal være et gyldigt tal';
            return;
        }

        if (position < 1) {
            errorContainer.innerText = 'Positionen skal være over 0';
            return;
        }

        modal('close', 'edit-spot-position');

        flatr
            .move_external_waitinglist_spot(externalWaitingList._id, window.spotPositionId, position - 1)
            .then(() => {
                n(`Position er blevet rykket!`);
                location.reload();
            })
            .catch((res) => {
                const error = res.responseJSON;
                if (error && (error.error_msg || error.message)) {
                    n(error.error_msg || error.message, 'error');
                } else {
                    genericErrorNotification(res);
                }
            });
    });

    const container = document.querySelector('.spots');
    externalWaitingList.list.sort((a, b) => a.position - b.position).forEach(spot => {
        const grid = element('div', {
            class: 'grid'
        });

        const positionElement = element('div', { innerText: spot.position + 1, className: 'clickable' });
        grid.appendChild(positionElement);

        grid.appendChild(element('div', { innerText: spot.subject.name }));

        const emailElement = grid.appendChild(element('div'));
        if (spot.subject.user) {
            emailElement.appendChild(element('a', { href: `/users/${spot.subject.user}`, innerText: spot.subject.email }));
        }
        else {
            emailElement.innerText = spot.subject.email;
        }

        const referenceElement = element(
            'div',
            {
                innerText: spot.reference ?? '',
                title: spot.reference ?? '',
                onclick: () => {

                    if (!spot.reference || spot.reference === '') return;

                    n('Tekst kopieret til udklipsholder')

                    navigator.clipboard.writeText(spot.reference)
                }
            }
        )

        referenceElement.style = 'cursor: pointer; color: rgb(150,150,150); overflow: hidden; text-overflow: ellipsis; white-space: nowrap; align-self: center; display: inline;'

        grid.appendChild(referenceElement)

        const isActivePlan = spot.subscription
            ? spot.subscription.plan.match(/active/)
            : spot?.subject?.matchOptions?.isActivePlan === true;
        grid.appendChild(element('div', { innerText: stateString(spot.subject.matchOptions?.state ?? 'accepted', isActivePlan) }));

        positionElement.addEventListener('click', () => {
            window.spotPositionId = spot._id;
            modal('open', 'edit-spot-position');
        });

        const deleteButton = element('img', { src: '/static/icons/remove.svg', className: 'clickable' });
        deleteButton.addEventListener('click', () => {
            confirm({
                title: `Fjern ventelisteopskrivning`,
                message: `Er du sikker på, at du vil fjerne ${spot.subject.name}, fra ${externalWaitingList.realEstate.name}?`,
                buttons: [
                    {
                        text: 'Annuller',
                        class: 'btn4',
                    },
                    {
                        text: 'Fjern ventelisteopskrivning',
                        class: 'btn1',
                        click: function () {
                            flatr
                                .delete_external_waitinglist_spot(externalWaitingList._id, spot._id)
                                .then((res) => {
                                    n(`${spot.subject.name} er blevet fjernet fra ventelisten`);
                                    grid.parentNode.removeChild(grid);
                                })
                                .catch((res) => {
                                    const error = res.responseJSON;

                                    if (error && (error.error_msg || error.message)) {
                                        n(error.error_msg || error.message, 'error');
                                    } else {
                                        genericErrorNotification(res);
                                    }
                                });
                        },
                    },
                ],
            });
        });

        grid.appendChild(deleteButton);
        container.appendChild(grid);
    });
}

function renderRealEstateOrganizations(realEstate) {
    const organizationId = realEstate.organization;
    const container = $('#real-estate-organization > .grid');
    container.innerHTML = '';
    if (organizationId) {
        flatr
            .get_organization(organizationId)
            .then((res) => {
                const orgElem = document.createElement('div');
                const nameElem = document.createElement('p');
                nameElem.innerText = res.responseJSON.name;
                orgElem.appendChild(nameElem);
                orgElem.addEventListener('click', () => {
                    window.location.href = `/organizations/${organizationId}`;
                });
                container.appendChild(orgElem);
            })
            .catch(genericErrorNotification);
    } else {
        const p = document.createElement('p');
        p.className = 'gray';
        p.innerText = 'Ejendommen er ikke tilknyttet en organisation';
        container.appendChild(p);
    }
}

function renderInterestListMembers(interestList) {
    const memberList = $('.interest-list-members > .grid');
    interestList.members.forEach((member) => {
        const memberListItem = document.createElement('div');
        memberListItem.className = 'member-list-item';
        memberListItem.innerHTML = `
            <div class="name">${member.user.name}</div>
            <div class="email">${member.user.email}</div>
        `;

        memberList.appendChild(memberListItem);
    });
}

function renderExternalWaitingListNewSpot(externalWaitingList) {
    const oldSubmit = $('#create-spot-submit');
    const newSubmit = oldSubmit.cloneNode(true);
    oldSubmit.parentNode.replaceChild(newSubmit, oldSubmit);

    newSubmit.addEventListener('click', () => {
        const errorElement = $('#create-spot-error');
        const email = $('#create-spot-email-input').value;
        const name = $('#create-spot-name-input').value;
        const telephone = $('#create-spot-telephone-input').value;
        const isActivePlan = document.querySelector('input[name="create-spot-active"]:checked').value == 'yes';
        const date = new Date($('#create-spot-payment-input').value);

        if (!email) {
            errorElement.innerText = 'Email skal udfyldes';
            return;
        }

        if (!name) {
            errorElement.innerText = 'Navn skal udfyldes';
            return;
        }

        if (!(date instanceof Date) || isNaN(date)) {
            errorElement.innerText = 'Ugyldig dato';
            return;
        }

        const subject = {
            email,
            matchOptions: {
                isActivePlan,
                startDate: date.toISOString()
            },
            name,
            telephone
        }

        flatr
            .fill_external_waitinglist_spot(externalWaitingList._id, subject)
            .then(() => {
                n(`Pladsen er blevet tilføjet`);
                location.reload();
            })
            .catch((res) => {
                const error = res.responseJSON;
                if (error && (error.error_msg || error.message)) {
                    n(error.error_msg || error.message, 'error');
                } else {
                    genericErrorNotification(res);
                }
            });
    });
}
