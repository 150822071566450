import { clicked_outside_elem } from 'shared/helpers';

export function confirm(options = {}) {
    const { title, message, template } = options;
    const buttons =
        Array.isArray(options.buttons) && options.buttons.length
            ? options.buttons
            : [
                  {
                      text: 'OK',
                      class: 'btn3',
                  },
              ];

    const confirmElem = document.createElement('div');
    confirmElem.id = '#confirm';
    confirmElem.className = 'confirm fadeIn';

    const dismiss = (confirmElem) => {
        if (confirmElem) {
            confirmElem.className = confirmElem.className.replace(/fadeIn/g, 'fadeOut');
        }
        setTimeout(function () {
            confirmElem &&
                confirmElem.parentElement &&
                confirmElem.parentElement.removeChild(confirmElem);
        }, 351);
    };

    const backdrop = document.createElement('div');
    backdrop.className = 'backdrop';
    const confirmBox = document.createElement('div');
    confirmBox.className = 'confirm-box';

    const listener = backdrop.addEventListener('mousedown', function (event) {
        if (clicked_outside_elem(event, confirmBox)) {
            backdrop.removeEventListener('mousedown', listener);
            dismiss(confirmElem);
        }
    });

    if (title) {
        const titleElem = document.createElement('h1');
        titleElem.innerText = title;
        titleElem.className = 'title';
        confirmBox.appendChild(titleElem);
    }

    if (!template && message) {
        const messageElem = document.createElement('p');
        messageElem.innerText = message;
        messageElem.className = 'message';
        confirmBox.appendChild(messageElem);
    }

    if (template && !message) {
        const templateElem = document.createElement('div');
        templateElem.innerHTML = template;
        templateElem.className = 'message';
        confirmBox.appendChild(templateElem);
    }

    const buttonContainer = document.createElement('div');
    buttonContainer.className = 'button-container';
    if (buttons.length > 2) {
        buttonContainer.className = 'button-container stacked';
    }

    const buttonClickHandler = (clickHandler, autoDismiss = true) => () => {
        clickHandler && clickHandler();
        autoDismiss && dismiss(confirmElem);
    };

    for (const button of buttons) {
        const buttonElem = document.createElement('button');
        buttonElem.className = button.class;
        buttonElem.innerText = button.text;
        buttonElem.onclick = buttonClickHandler(button.click, button?.autoDismiss ?? true);
        buttonContainer.appendChild(buttonElem);
    }

    confirmBox.appendChild(buttonContainer);
    backdrop.appendChild(confirmBox);
    confirmElem.appendChild(backdrop);
    document.body.appendChild(confirmElem);
}
