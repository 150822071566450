
/*
 *      NOTIFICATIONS
 *      - available types: error, succes, info
*/

export function n(txt, type = 'succes', timeout = 2000, delay = 300) {
    if (['succes', 'error', 'alert'].indexOf(type) === -1) {
        console.error('Bad notification type');
        return;
    }

    const closeNoti = noti => {
        noti.className = noti.className.replace(/fadeIn/g, 'fadeOut');
        setTimeout(() => container.removeChild(noti), 410);
    };

    let container = document.querySelector('.n-container');
    if (!container) {
        container = document.createElement('div');
        container.className = 'n-container';
        document.body.appendChild(container);
    }

    const noti = document.createElement('div');
    noti.className = `n fadeIn ${type}`;
    noti.addEventListener('click', () => {
        closeNoti(noti);
    });


    const msg = document.createElement('span');
    msg.innerText = txt;
    noti.appendChild(msg);
    setTimeout(() => container.appendChild(noti), delay);
    if (typeof (timeout) === 'number' && timeout > 0) {
        setTimeout(() => {
            closeNoti(noti);
        }, timeout + delay);
    }
}

const readSpeedInWPM = 225;

const getReadTimeInMs = str => {
    const wordCount = str.split(' ').length;

    return wordCount / readSpeedInWPM * 60 * 1000;
};

// genericErrorNotification :: XhrResponse? -> _
export function genericErrorNotification(res) {
    const message = res?.responseJSON?.message ?? 'Der opstod en uventet fejl. Prøv igen og hvis problemet fortsætter, kontakt hello@flatr.dk';

    if (res) {
        console.error(`${res.status || res.statusCode}`, (res.responseJSON || res.responseText));
    }

    n(message, 'error', Math.max(2500, getReadTimeInMs(message)), 0);
}