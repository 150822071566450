


class winstonWrapper {
  constructor(logger) {
    this.logger = logger;
  }

  error(message, error, ...meta) {
    if (message instanceof Error) {
      return this.logger.error(
        message.name,
        message,
        [error, ...meta]
      )
    }

    return this.logger.error(message, error, meta);
  }

  warn(...args) {
    return this.logger.warn(...args);
  }

  info(...args) {
    return this.logger.info(...args);
  }

  debug(...args) {
    return this.logger.debug(...args);
  }

  log(...args) {
    return this.logger.info(...args);
  }
}

module.exports = winstonWrapper;
