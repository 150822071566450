export function initializeCheckboxes() {
    // replace svg checkboxes
    const checkbox_elems = Array.from(
        document.getElementsByClassName('checkbox_svg'),
    );
    checkbox_elems.forEach(cel => {
        const svg_html = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path fill="none" fill-rule="evenodd" stroke="${cel.dataset.color}" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18 5L7 16l-5-5"/>
        </svg>`;
        cel.outerHTML = svg_html;
    });
}