import { clicked_outside_elem } from 'shared/helpers';
import AmplitudeService from 'shared/amplitude.service';

const _modalEvents = {};
let _findInDOMRetries = 0;

export function addModalEvent(id, event, func) {
    if (!['open', 'close'].includes(event)) {
        throw new Error(`Invalid modal event '${event}'`);
    }

    if (typeof(id) !== 'string') {
        throw new Error(`Invalid modal id '${id}'`);
    } else {
        if (!document.querySelector(`#${id}`)) {
            if (_findInDOMRetries === 3) {
                throw new Error(`Modal with id '${id} does not exist'`);
            } else {
                _findInDOMRetries++;
                return setTimeout(() => addModalEvent(id, event, func), 100);
            }
        }
    }

    _findInDOMRetries = 0;

    if (typeof func !== 'function') {
        throw new Error(`Function passed to modal event is not a function`);
    }

    if (!_modalEvents[id]) {
        _modalEvents[id] = {};
    }

    if (!_modalEvents[id][event]) {
        _modalEvents[id][event] = [];
    }

    _modalEvents[id][event].push(func);
}


export function modal(command, id, data) {
    const modal_elem = document.getElementById(id);
    if (!modal_elem) {
        return Error('modal elem does not exist');
    }

    AmplitudeService.logEvent('TRIGGER_MODAL', { command, id, data });

    // command: open
    if (command === 'open')  {

        // set overlay classes
        document.body.className = document.body.className + ' overlay';
 
        // configure close btn event
        const closeListener = function closeListener(e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            modal_elem.querySelector('.close').removeEventListener('click', closeListener);
            modal('close', id);
        };
 
        modal_elem.querySelector('.close').addEventListener('click', closeListener);

        // open modal
        modal_elem.className = 'modal-overlay opened';

        // run open events
        if (_modalEvents[id]?.open?.length) {
            _modalEvents[id]?.open.forEach(func => func());
        }

        // if click outside modal window, press close button (also executes close eventlisteners)
        const listener = modal_elem.addEventListener('mousedown', function(event) {
            const modal_box = modal_elem.children[0];
            const clicked_outside = clicked_outside_elem(event, modal_box);

            if (clicked_outside) {
                modal_elem.removeEventListener('mousedown', listener);
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                modal_box.querySelector('.close').click();
            }
        });

    // command: close
    } else if (command === 'close') {
        document.body.className = document.body.className.replace(
            /\ overlay/g,
            '',
        );

        // run close events
        if (_modalEvents[id]?.close?.length) {
            _modalEvents[id]?.close.forEach(func => func(e));
        }

        // close modal
        modal_elem.className = 'modal-overlay closing';
        setTimeout(() => (modal_elem.className = 'modal-overlay closed'), 420); // has to match css animation + a little (for slow devices)

    // command: load | clear
    } else if (command === 'load' || command === 'clear') {
        if (!data && command === 'load') {
            return console.warn('No data to load in modal');
        }
        const elems = Array.from(modal_elem.querySelectorAll('[data-id]'));
        for (let k in data) {
            const els = elems.filter(el => el.dataset['id'] === k);
            if (!Array.isArray(els) || els.length === 0) {
                return;
            }

            els.forEach(el => {
                if (!el) {
                    throw Error(
                        `Modal '${id}' is missing elem with dataset.id '${k}'`,
                    );
                }

                let setStringAttr = str => el.innerText = str;

                if (
                    ['INPUT', 'TEXTAREA'].includes(el.tagName)
                ) {
                    setStringAttr = str => {
                        el.value = str
                        el.dispatchEvent(new Event('input'));
                    };
                } else if (el.tagName === 'IMG') {
                    setStringAttr = str => el.src = str;
                }


                if (command === 'load') {
                    if(typeof(data[k]) === 'string') {
                        setStringAttr(data[k]);
                    } else if(typeof(data[k]) === 'object' && data[k] !== null) {
                        Object.keys(data[k]).forEach(attrKey => {
                            el[attrKey] = data[k][attrKey];
                        });
                    } else if(typeof(data[k]) === 'number' && !isNaN(data[k])) {
                        setStringAttr('' + data[k]);
                    } else if(typeof(data[k]) === 'undefined' || data[k] === null) {
                        console.warn(`modal.load was called with undefined value for key '${k}'`);
                        setStringAttr('');
                    } else {
                        throw new Error(`Cannot populate modal with data of type '${typeof(data[k])}'`);
                    }
                } else if(command === 'clear') {
                    setStringAttr('');
                } else {
                    throw new Error(`Modal command '${command}' does not exist`);
                }
            });
        }
    }
}